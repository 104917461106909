/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #00953f;
$secondary: #97994F;
// $success: #00953f;
$warning: #f5cd00;

.theme-navbar {
    background-color: #00953f;
    color: #fff;

    .nav-item {
        border-bottom: solid 3px #97994F;
    }

    .nav-link {
        color: #fff !important;
    }

    .nav-item:hover {
        color: #fff;
        text-decoration: none;
        background-color: #97994F;
        border-radius: 3px;
    }
}

.theme-footer {
    background-color: #97994F;
    color: #fff;

    a {
        color: #fff;
    }

    a:hover {
        color: #00953f;
    }
}

.brand-logo {
    height: auto;
    width: 180px;
}

.secondary-logo {
    height: auto;
    width: 70px;
}

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss"